import { inject } from '@angular/core';
import { Injectable } from '@banta/common';
import { Requester, RequestPerformer } from '@tytapp/api/requester';
import { buildQuery, LoggerService } from '@tytapp/common';
import { Observable } from 'rxjs';

export interface ApiRequest<T = any> {
    method: string;
    path: string;
    query: Record<string, string>;
    headers: Record<string, string|string[]>;
    body: T;
}

export type ApiPerformer<ResponseT = any> = (request: ApiRequest) => Observable<ResponseT>;
export type ApiMiddleware<ResponseT = any> = (request: ApiRequest, next: ApiPerformer<ResponseT>) => Observable<ResponseT>;

@Injectable()
export class ApiMiddlewareService {
    private apiRequester = inject(Requester);
    private logger = inject(LoggerService).configure({ source: 'apimiddle' });

    /**
     * Install the given middleware into the API request stack.
     * @param middleware
     */
    install(name: string, middleware: ApiMiddleware) {
        let inner = this.apiRequester.requestPerformer;
        let callMiddleware: RequestPerformer = (method, path, query, headers, body) => {

            //this.logger.info(`${method} ${path}?${buildQuery(query)}: Running middleware ${name}`);

            return middleware(
                { method, path, query, headers, body },
                (request) => {
                    //this.logger.info(`${method} ${path}?${buildQuery(query)}: Finished middleware ${name}`);
                    return inner(request.method, request.path, request.query, request.headers, request.body)
                }
            );
        }

        this.apiRequester.requestPerformer = callMiddleware;
    }
}