import { inject, Injectable } from '@angular/core';
import { Base64, LoggerService } from '@tytapp/common';
import { isClientSide, isOnline, OfflineError } from '@tytapp/environment-utils';

/**
 * A simple service for storing persisted values on the client.
 * When in SSR or worker mode, all actions are no-op.
 */
@Injectable()
export class ClientPersistenceService {
    logger = inject(LoggerService);
    base64 = inject(Base64);

    /**
     * Call the fetcher function when we are connected to a network and store the result within the persistence
     * system. If we are offline, attempt to acquire a value from the persistence system and return that. If no
     * value is available, throw an exception indicating network connection is required.
     * @param key
     * @param fetcher
     * @returns
     */
    async fetchWhenOnline(key: string, fetcher: () => Promise<any>) {
        if (isOnline()) {
            let result = await fetcher();
            this.set(key, result);
            return result;
        } else {
            let result = await this.get(key);
            if (!result) {
                throw new OfflineError(`ClientPersistence key ${key} was not previously saved`);
            }

            return result;
        }
    }

    async get(key: string) {
        if (!isClientSide())
            return undefined;

        try {
            return JSON.parse(this.base64.decode(localStorage[`tyt:persisted:${key}`]));
        } catch (e) {
            this.logger.error(`[Client Persistence] Failed to parse stored data for key '${key}': ${e.message}`);
            this.logger.error(`[Client Persistence] Data will be deleted. Data was:`);
            delete localStorage[`tyt:persisted:${key}`];
        }

        return undefined;
    }

    async set(key: string, value: any) {
        if (!isClientSide())
            return;
        localStorage[`tyt:persisted:${key}`] = this.base64.encode(JSON.stringify(value));
    }

    async delete(key: string) {
        if (isClientSide())
            delete localStorage[`tyt:persisted:${key}`];
    }
}