import { inject, makeStateKey, TransferState } from '@angular/core';

import { Injectable } from '@banta/common';
import { REQUEST } from '../../express.tokens';
import { ApiMiddlewareService, ApiRequest } from '@tytapp/app/middleware/api-middleware.service';
import { buildQuery, LoggerService, PeriodicTaskService } from '@tytapp/common';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import type * as express from '../../common/express-ssr';
import { NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@tytapp/environment';
import { HttpErrorResponse } from '@angular/common/http';

interface SavedApiCall {
    path: string;
    response: any;
}

const API_CALL_TRANSFER = makeStateKey<SavedApiCall[]>('apiCallTransfer');

/**
 * Transparent caching of API calls made in SSR for replay on CSR using Angular's
 * TransferState service.
 *
 * This middleware used to handle duplication of API requests, but that has been moved to the ApiReuse middleware
 * so that API calls can be reused on the client side as well.
 */
@Injectable()
export class ApiStateTransfer {
    private request = inject<express.ExpressRequest>(REQUEST, { optional: true });
    private transferState = inject(TransferState);
    private apiMiddleware = inject(ApiMiddlewareService);
    private logger = inject(LoggerService);
    private periodicTasks = inject(PeriodicTaskService);
    private router = inject(Router);
    private snackbar = inject(MatSnackBar);

    private apiCalls: SavedApiCall[] = [];
    private missedApiCallCount = 0;

    private replay(request: ApiRequest) {
        if (request.method !== 'GET')
            return undefined;

        let fullPath = `${request.path}?${buildQuery(request.query)}`;

        let matchingCallIndex = this.apiCalls.findIndex(x => x.path === fullPath);
        if (matchingCallIndex >= 0) {
            let matchingCall = this.apiCalls[matchingCallIndex];

            if (!this.request)
                this.logger.info(`[ApiStateTransfer] Replayed API call ${matchingCall.path}`);

            if (matchingCall.response.$error)
                return throwError(() => matchingCall.response.$error);

            return of(matchingCall.response);
        } else if (this.navigationHasApiCalls) {
            this.missedApiCallCount += 1;
            this.logger.warning(
                `[ApiStateTransfer] Call missing in server response: ${fullPath}`
            );
        }
    }

    /**
     * Store an API request/response pair for sending to clients for replay. Normally this
     * is handled transparently. In cases where API calls are cached between SSR requests,
     * it may be desirable to inject those cached API calls so that they are not replayed
     * on the client.
     *
     * @param method
     * @param path
     * @param query
     * @param response
     * @returns
     */
    store(request: ApiRequest, response: any) {
        if (!this.request || request.method !== 'GET')
            return;

        this.apiCalls.push({ path: `${request.path}?${buildQuery(request.query)}`, response });
        this.transferState.set(API_CALL_TRANSFER, this.apiCalls);
    }

    prepopulate(path: string, query: Record<string, string>, value: any) {
        this.store({ method: 'GET', headers: {}, body: undefined, path, query }, value);
    }

    private installCapturer() {
        this.transferState.set(API_CALL_TRANSFER, this.apiCalls);
        this.apiMiddleware.install('capturer', (req, next) => {
            return next(req)
                .pipe(map(res => (this.store(req, res), res)))
                .pipe(catchError(err => {
                    let httpError = err.$http as HttpErrorResponse;
                    let cacheableStatusCodes = [404];
                    if (cacheableStatusCodes.includes(httpError?.status))
                        this.store(req, { $error: err });

                    throw err;
                }))
            ;
        });
    }

    navigationHasApiCalls: boolean = false;

    private installReplayer() {
        this.apiCalls = this.transferState.get(API_CALL_TRANSFER, []);
        this.apiMiddleware.install('replayer', (request, next) => this.replay(request) ?? next(request));
        this.navigationHasApiCalls = false;

        if (this.apiCalls.length > 0) {
            this.navigationHasApiCalls = true;

            if (environment.showDevTools) {
                this.logger.info(`[ApiStateTransfer] Ready to replay ${this.apiCalls.length} stored API calls.`);
                this.logger.inspect(this.apiCalls.slice());
            }
        }

        this.periodicTasks.scheduleOnce(10_000, () => {
            if (environment.showDevTools && this.apiCalls.length > 0) {
                this.logger.info(`[ApiStateTransfer] ${this.apiCalls.length} API calls were not consumed:`);
                this.logger.inspect(this.apiCalls);
            }

            if (environment.showDevTools && this.missedApiCallCount > 0) {
                this.logger.warning(
                    `[ApiStateTransfer] ${this.missedApiCallCount} API calls were not included in server response. `
                    + `Ensure these API calls occurs in both CSR and SSR or use ApiStateTransfer.store()/prepopulate().`
                );

                this.snackbar.open(`[Dev] SSR did not include ${this.missedApiCallCount} API calls`, undefined, {
                    panelClass: 'error',
                    duration: 3000
                });
            }
        });

        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                if (environment.showDevTools && this.apiCalls.length > 0) {
                    this.logger.warning(`[ApiStateTransfer] ${this.apiCalls.length} stored API call(s) were left unused before navigation.`);
                }
                this.navigationHasApiCalls = false;
                this.apiCalls = [];
                this.missedApiCallCount = 0;
            }
        });
    }

    install() {
        if (this.request)
            this.installCapturer();
        else
            this.installReplayer();
    }
}